<template>
    <div class="searchbox landing">
        <!-- FIELDS -->
        <form action="" method="post" autocomplete="off" ref="searchboxForm">
            <option-apr></option-apr>
        </form>
    </div>
</template>

<script>
    import SearchboxMixin from "./SearchboxMixin.js";
    import RequestMixin from "./Requests.js";
    import OptionAPR from "./options/OptionsAPR.vue";

    export default {
        mixins: [ SearchboxMixin, RequestMixin ],
        components: { 'option-apr': OptionAPR },
        data(){
            return {
                type: 'searchbox',
                date1: null,
                date2: null,
            }
        },
        mounted(){
            let self = this
            this.$root.$on('session-received', function (data) {
                if (data.searchbox) {
                    // use airport info from page (not from session)
                    if (_.get(self.query, 'airport.code')) {
                        data.searchbox.airport = _.get(self.query, 'airport');
                    }

                    // re-store session
                    self.$store.dispatch('updateSession', data.searchbox);

                    self.setPackage('APR');

                } else {
                    self.setPackage('APR');
                }
            })
            this.initSearchboxForm()
        },
        watch: {
            'query.date1': {
                handler: function (val, oldVal) {
                    if (val) {
                        this.date1 = moment(val).format('ddd, MMM D')
                    }
                }
            },
            'query.date2': {
                handler: function (val, oldVal) {
                    if (val) {
                        this.date2 = moment(val).format('ddd, MMM D')
                    }
                }
            },
        },
        methods: {
            setTab(tab) {
                this.$store.dispatch('updatePackage', 'APR');
                this.setPageHeading('apr')
                this.$store.dispatch('updateTab', tab);
            },

            setPageHeading(tab) {
                if (!this.h1) {
                    var h1 = document.querySelector('#hero .hero-heading h1');
                    var h2 = document.querySelector('#hero .hero-heading h2');
                    if (this.headings) {
                        h1.innerHTML = this.headings[tab]['h1'];
                        h2.innerHTML = this.headings[tab]['h2'];
                    }
                }
            },

            setPackage(pckg) {
                this.$store.dispatch('updatePackage', pckg)
                this.$store.dispatch('updateTab', 'parking')
            },
        }
    };
</script>
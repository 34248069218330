import Stars from "./../components/stars";
import AlertBar from "./../components/AlertBar.vue"
import SearchFilter from "./../components/search-filter";
import * as VueGoogleMaps from 'vue2-google-maps';
import TrackSearchImpressions from '../../../shared/js/track-search-impressions'

if (apr.app_page_name === 'search_results') {
    Vue.use(VueGoogleMaps, {load: window.googleMapsConfig});
}

Vue.component('page-search', {
    components: {
        Stars,
        'alert-bar': AlertBar,
        'gmap': VueGoogleMaps.Map,
        'gmarker': VueGoogleMaps.Marker,
        'ginfoWindow': VueGoogleMaps.InfoWindow
    },

    mixins: [SearchFilter, TrackSearchImpressions],

    data: function () {
        return {
            pageLoaded: false,
            airport: {},
            dates: {},

            search: [],

            token: apr.search_token,
            more_coming: apr.more_coming,
            map: null,

            email: null,
            emailOptIn: true,
            emailSending: false,
            emailErrorMessage: '',

            mapObj: {
                center: {lat: 0, lng: 0},
                mapTypeId: "roadmap",
                zoom: 10,
                minZoom: 4,
                options: {
                    styles: [
                        {featureType: "poi", elementType: "labels", stylers: [{"visibility": "off"}]}
                    ],
                    controlSize: 32,
                },
            },
            markers: [],
            infoWindowObj: {
                opened: false,
                position: {lat: 0, lng: 0},
                options: {},
                content: '',
                markerData: {}
            },

            apr_results: 0,
	        apr_filtered: 0,
            psf_results: 0,

            filter_list: [],
            filter_options: [],
            sort_option: 'popular-asc',

	        showSafetyMessage: null,

            error_dialog: {
                show: false,
                bold: `${apr.soldout} is <span class="highlight">sold out.</span>`,
                normal: 'Lots are selling out quick for your travel dates.<br/>Take a look at some other great options',
                excerpt: 'Here are some other great options.'
            },
            displaySavings: apr.displaySavings,
            savingsType: apr.savingsType,
            savingsAmount: apr.savingsAmount,
            possibleSavings: apr.possibleSavings,
	        savingsWorking: false,
        }
    },

    computed: {
        soldout() {
            return _.filter(apr.search_apr.soldout, (item) => {
                return _.findIndex(this.search, ['id', item.id]) === -1;
            });
        },
        apr_soldout_results() {
            return this.soldout.length;
        },
        alert_bar_text_alt() {
            return this.more_coming ? 'Searching for the best parking deals available...' : 'Sorry, there are no parking options available at this time.';
        }
    },

    mounted() {

        // filter functions
        this.detectHideFilter();
        this.selectSortByOption();

        this.initStickyMap();
        this.initCouponModal();

        if (this.more_coming) {
            this.reloadResultsFromServer();
        }

        this.airport = apr.search_apr.airport;
        this.dates = {
            checkindate: apr.search_apr.search.data.checkindate.date_format_2,
            checkoutdate: apr.search_apr.search.data.checkoutdate.date_format_2,
        };

        this.filter_list = apr.search_apr.meta.options;
        this.apr_results = apr.search_apr.meta.apr_count;
        this.psf_results = apr.search_apr.meta.psf_count;

        this.map = this.$refs.searchResultsMap;
        this.map.$mapCreated.then(() => {
            this.setMapCenter();
            this.setMapOptions();
            this.filterProducts();

            this.pageLoaded = true;
            this.mapFitBounds();

            if (!this.more_coming) {
                this.trackImpressions();
            }
        });

        if (apr.soldout) {
            this.error_dialog.show = true
        }

        if (apr.search_apr.meta.tweak) {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'Search Tweaks',
                'EventAction': `${this.airport.code} Tweaked`,
                'EventLabel': `${this.airport.code} ${apr.search_apr.meta.tweak}`
            });
        }

        // for back button functionality
        window.addEventListener("popstate", function (event) {
            if (event.state && event.state.action === "product") {
                window.location.reload();
            }
        }, false);

        this.trackMapProductClick();

        let label = apr.sort == 'rate-asc' ? apr.sort : 'control'
        dataLayer.push({
            'event': 'GaEvent',
            'EventCategory': 'Search Results Sorting',
            'EventAction': `${this.airport.code} ${label}`,
        });

        this.listen();
    },

    methods: {
        applySavings(amount) {
        	this.savingsWorking = true;
            this.$http.get(`/ping?feature=savings&value=${amount}`).then((response) => {
                dataLayer.push({
                    'event': 'GaEvent',
                    'EventCategory': 'Instant Special',
                    'EventAction': 'clicked',
                });
                location.reload();
            }, (response) => {
	            this.savingsWorking = false;
                // alert('Sorry we could not get that page for you')
            })
        },

        showEmailModal() {
            $('#Email_Modal').modal('show')
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'Saved Search',
                'EventAction': 'Modal - Opened',
                'EventLabel': ''
            })
        },

        sendSearchResults() {
            let self = this
            let email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (email_regex.test(this.email)) {
                this.emailSending = true
                this.$http.post(apr.saved_search_url, {
                    'email_address': this.email,
                    'token': apr.search_apr.search.data.token,
                    'optin': this.emailOptIn
                }).then((response) => {
                    self.emailErrorMessage = null
                    self.email = null
                    self.emailSending = false
                    dataLayer.push({
                        'event': 'GaEvent',
                        'EventCategory': 'Saved Search',
                        'EventAction': 'Modal - Submitted',
                        'EventLabel': ''
                    })
                    $('#Email_Modal').modal('hide')
                    swal({
                        title: "Email Sent!",
                        type: "success",
                        timer: 2000,
                        showConfirmButton: false
                    });
                }, (response) => {
                    self.emailErrorMessage = 'Please supply a valid email address'
                })
            } else {
                self.emailErrorMessage = 'Please supply a valid email address'
            }
        },

        hideEmailModal() {
            this.emailErrorMessage = null
            this.email = null
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'Saved Search',
                'EventAction': 'Modal - Closed',
                'EventLabel': ''
            })
            $('#Email_Modal').modal('hide')
        },

        setMapCenter() {
            this.mapObj.center = {
                lat: Number(this.airport.geo.latitude),
                lng: Number(this.airport.geo.longitude)
            }
        },

        setMapOptions() {
            let options = {
                options: {
                    scrollwheel: false,
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: google.maps.ControlPosition.TOP_LEFT
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP
                    },
                    scaleControl: true,
                    streetViewControl: true,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP
                    }
                }
            }

            $.extend(this.mapObj, options)
        },

        filterProducts() {
            let self = this

            if (this.filter_options.length > 0) {
                this.search = _.filter(apr.search_apr.results, function (o) {
                    if (o.provider == 'APR') {
                        let result = false

                        for (var i = 0, len = self.filter_options.length; i < len; i++) {
                            if (o.options.indexOf(self.filter_options[i]) > -1) {
                                result = true
                            }
                        }
                        return result
                    }

                    if (o.provider == 'PSF' && self.filter_options.indexOf('Hotel') > -1) {
                        return true
                    }
                })

            } else {
                let results = apr.search_apr.results

                _.map(results, function(result) {
                    result.distance = parseFloat(result.distance)
                });

                this.search = results
            }

            this.search = _.uniqBy(this.search, (result) => {
                if (result.provider === 'PSF') {
                    return result.hotel_id;
                }

                return result.id;
            });

            this.sortList()

            this.getPins(this.search);

            if(this.isTouchDevice() === false) {
                setTimeout(() => {
                    $('.psf i[data-toggle="tooltip"]').tooltip()
                }, 100)
            }
        },

        isTouchDevice(){
            return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
        },

        sortList() {
            let [type, direction] = this.sort_option.split("-")

            if (type == 'rating') {
                this.search = _.orderBy(this.search, function (o) {
                    if (o.provider == 'APR') {
                        return o.ratings.rating
                    }
                    return o.ratings.average_rating
                }, direction)

            } else if (type == 'distance') {
                this.search = _.orderBy(this.search, [type], direction)

            } else if (type == 'rate') {
                let [lots, hotels] = _.partition(this.search, ['provider', 'APR'])

                this.search = _.orderBy(lots, [type], direction)
                this.search = this.search.concat(hotels)
            } else {
                this.search = _.orderBy(this.search, ['position'], direction)
            }
        },

        getPins(lots) {
            this.markers = []
            this.getAirportMarker()

            for (let lot of lots) {
                let marker = this.getMarkerProperties(lot);
                this.markers.push(marker)
            }
        },

        getAirportMarker() {
            this.markers = [{
                type: this.airport.type === 'CruisePort' ? 'cruiseport' : 'airport',
                position: {lat: Number(this.airport.geo.latitude), lng: Number(this.airport.geo.longitude)},
                icon: {
                    url: apr.storage_url + '/img/icons/' + (this.airport.type === 'CruisePort' ? 'cruise-pin.png' : 'airport-pin.png'),
                    scaledSize: new google.maps.Size(45, 36),
                    anchor: new google.maps.Point(23, 36)
                },
                animation: google.maps.Animation.FADE,
                draggable: false
            }]
        },

        mapFitBounds() {
            let bounds = new google.maps.LatLngBounds();

            this.markers.forEach((marker) => {
                let point = new google.maps.LatLng(marker.position.lat, marker.position.lng);
                bounds.extend(point);
            })

            if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
                var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
                var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
                bounds.extend(extendPoint1);
                bounds.extend(extendPoint2);
            }

            this.map.fitBounds(bounds)
        },

	    getDiscountPercentage(original, discounted){
		    let value = (original/discounted) * 100
		    value = (value - 100) * -1
		    return Math.round(value)
	    },

        isDestinationMarker(marker) {
            return (_.indexOf(['airport', 'cruiseport'], marker.type) !== -1)
        },

        mouseOverMarker(hover, marker) {
            if (this.isDestinationMarker(marker)) {
                return
            }

            // on mouse out - dont make it inactive
            if (marker.active) {
                return
            }

            marker.icon = this.getMarkerIcon(marker.provider, hover)
            marker.zIndex = hover ? 999999 : marker.zIndexOriginal
        },

        markerStatusChange(marker) {
            let isActive = marker.active ? true : false

            marker.icon = this.getMarkerIcon(marker.provider, isActive)
            marker.zIndex = isActive ? 999998 : marker.zIndexOriginal
        },

        highlightMarker(product) {
            if (_.isEmpty(product.geo)) {
                return;
            }

            let marker = {
                position: {lat: Number(product.geo.latitude), lng: Number(product.geo.longitude)},
                icon: this.getMarkerIcon(product.provider, true),
                label: this.getMarkerLabelProperties(product.provider, product),
                zIndex: 999999,
                optimized: false,
                hovered: true,
                provider: product.provider,
            }

            this.markers.push(marker)
        },

        unHighlightMarker() {
            this.markers = this.markers.filter((marker) => marker.hovered === undefined);
        },

        getMarkerIcon(provider, hover = false) {
            return {
                url: this.getMarkerIconPath(provider, hover),
                scaledSize: new google.maps.Size(45, 36),
                anchor: new google.maps.Point(23, 36),
                labelOrigin: new google.maps.Point(22, 14)
            }
        },

        getMarkerIconPath(provider, hover) {
            if (provider === 'APR') {
                return apr.storage_url + (hover ? '/img/icons/lot-hover-pin.png' : '/img/icons/lot-pin.png')
            }

            return apr.storage_url + (hover ? '/img/icons/hotel-hover-pin.png' : '/img/icons/hotel-pin.png')
        },

        getMarkerLabelProperties(provider, lot) {
            if (provider === 'APR') {
                return {
                    text: "$" + Math.round(lot.rate),
                    color: 'white',
                    fontSize: '0.75rem'
                }
            }

            return {
                text: " ",
                color: '',
                fontSize: ''
            }
        },

        openInfoWindow(marker) {
            let self = this
            this.infoWindowObj.opened = false

            if (marker.geo === undefined) {
                this.infoWindowObj.opened = false
                return false
            }

            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'Map',
                'EventAction': 'info window open',
                'EventLabel': ''
            })

            setTimeout(() => {
                this.infoWindowObj.opened = true
                this.infoWindowObj.position = {lat: Number(marker.geo.latitude), lng: Number(marker.geo.longitude)}
                this.infoWindowObj.options = {
                    pixelOffset: new google.maps.Size(0, -35),
                    content: this.getInfoWindowContent(marker)
                }

                this.infoWindowObj.content = this.getInfoWindowContent(marker)
                this.infoWindowObj.markerData = marker;

                // reset markers
                self.makeAllMarkersInactive()

                // make current as active
                if (!self.isDestinationMarker(marker)) {
                    marker.active = true
                    self.markerStatusChange(marker)
                }
            }, 100)
        },

        getMarkerProperties(lot) {
            let props = {
                position: {lat: Number(lot.geo.latitude), lng: Number(lot.geo.longitude)},
                animation: google.maps.Animation.FADE,
                draggable: false,
                flat: true,

                provider: lot.provider,
                name: lot.name,
                media: {logo: lot.media.logo},
                geo: {
                    latitude: lot.geo.latitude,
                    longitude: lot.geo.longitude
                },
                rate: lot.rate,
                rate_str: lot.rate_str,
                distance: lot.distance,
            };

            let props2 = {}
            if (lot.provider === 'APR') {
                props2 = {
                    icon: this.getMarkerIcon(lot.provider),
                    label: this.getMarkerLabelProperties(lot.provider, lot),
                    zIndex: lot.id,
                    zIndexOriginal: lot.id,
                    ratings: {
                        rating: lot.ratings.rating,
                        rating_count: lot.ratings.rating_count
                    },
                    content: {slug: lot.content.slug},
                    id: lot.id,
                }

            } else if (lot.provider === 'PSF') {
                props2 = {
                    icon: this.getMarkerIcon(lot.provider),
                    label: this.getMarkerLabelProperties(lot.provider, lot),
                    zIndex: lot.hotel_id,
                    zIndexOriginal: lot.hotel_id,
                    ratings: {
                        average_rating: lot.ratings.average_rating,
                        reviews_quantity: lot.ratings.reviews_quantity
                    },
                    content: {slug: this.filters.hotelUrl(lot.slug, 'apr')},
                    id: lot.hotel_id,
                }
            }

            $.extend(props, props2);
            //console.log(props)
            return props;
        },

        clickedMap() {
            this.infoWindowObj.opened = false
            this.makeAllMarkersInactive()
        },

        makeAllMarkersInactive() {
            let self = this

            _.map(self.markers, function (m) {
                if (m.active) {
                    m.active = false
                    self.markerStatusChange(m)
                }
                return m
            });
        },

        getInfoWindowContent(marker) {
            let html = `
                    <div class="row map-marker">

                        `;

            if (marker.provider === 'APR') {
                html += `
                    <div class="col-3 col-1">
                        <a href="/${marker.content.slug}" target="_blank">
                            <img src="${marker.media.logo}" alt="${marker.name}" width="100%" />
                        </a>
                    </div>
                    <div class="col-9 col-2">
                        <h3><a href="/${marker.content.slug}" target="_blank">${marker.name}</a></h3>
                        <div class="ratings py-2">
                            ${this.filters.drawStarsWithText(marker.ratings.rating, marker.ratings.rating_count)}
                        </div>
                        <div class="distance"><i class="fas fa-plane"></i> ${marker.distance} miles from ${this.airport.code} airport</div>
                    </div>
                    <a href="/${marker.content.slug}" class="btn btn-secondary btn-block mt-2" target="_blank">
                        Park Here ${(marker.rate_str ? ' | ' + marker.rate_str + ' <small>per day</small>' : '')}
                    </a>`;

            } else {
                html += `
                    <div class="col-3 col-1">
                        <a href="${marker.content.slug}" target="_blank" rel="nofollow">
                            <img src="${marker.media.logo}" alt="${marker.name}" width="100%" />
                        </a>
                    </div>
                    <div class="col-9 col-2">
                        <h3><a href="${marker.content.slug}" target="_blank" rel="nofollow">${marker.name}</a></h3>
                        <div class="ratings py-2">
                            ${this.filters.drawStarsWithText(marker.ratings.average_rating, marker.ratings.reviews_quantity)}
                        </div>
                        <div class="distance"><i class="fas fa-plane"></i> ${marker.distance} miles from ${this.airport.code} airport</div>
                    </div>
                    <a href="${marker.content.slug}" class="btn btn-green btn-wide mt-2" target="_blank" rel="nofollow">Sleep & Park Here</a>`;
            }

            html += '</div>';

            return html;
        },

        initStickyMap: function () {
            let map = $('#map_container');

            $(window).scroll(function (event) {
                setInterval(() => {
                    let st = $(window).scrollTop();
                    let heightToScroll = 200 - ($("#search_results .alert").length === 1 ? 0 : 32);

                    if (st > heightToScroll) {
                        map.addClass('sticky-map');
                    } else {
                        map.removeClass('sticky-map');
                        let height = window.innerHeight - (heightToScroll - $(window).scrollTop())
                        $("#map_container").css('height', height)
                    }
                }, 20);
            });
        },

        trackEvent(name) {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'Search Results',
                'EventAction': name,
                'EventLabel': ''
            });
        },

        trackMapProductClick(marker) {
            $(document).on('click', '.map-marker a', () => {
                let lot = this.infoWindowObj.markerData;

                if (_.get(lot, 'provider') === 'APR') {
                    this.ecommerceTracking(lot, 1);
                }
            })
        },

	    initCouponModal(){
        	if(this.displaySavings && !this.savingsAmount) {
		        setTimeout(() => {
			        $('#SearchCouponModal').modal('show')
		        }, 5000) // 5 seconds
	        }
	    },

        ecommerceTracking(lot, index) {
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list': 'Search Results'},
                        'products': [{
                            'name': `${this.airport.code} ${lot.name}`,
                            'id': this.airport.code,
                            'price': lot.rate,
                            'brand': '',
                            'category': '',
                            'variant': 'available',
                            'position': index
                        }]
                    }
                }
            })
        },

        listen() {
            let channel = APR_PUSHER_SEARCH_CHANNEL.replace('%s', this.token);

            if (this.more_coming) {
                window.onbeforeunload = function () {
                    return 'Searching for the best parking deals available...';
                };
            }

            window.Echo.channel(channel)
                .listen(window.APR_PUSHER_SEARCH_RESULTS_UPDATED_EVENT, data => {
                    this.appendSearchResult(_.get(data, 'result'));
                })
                .listen(window.APR_PUSHER_SEARCH_META_UPDATED_EVENT, data => {
                    apr.search_apr.meta.options = _.get(data, 'meta.data.options');
                    this.filter_list = _.get(data, 'meta.data.options');
                })
                .listen(window.APR_PUSHER_SEARCH_STATUS_UPDATED_EVENT, data => {
                    // Search was updated somewhere else
                    if (!this.more_coming) {
                        location.reload();
                    }

                    window.onbeforeunload = null;
                    this.more_coming = false;
                    this.pageLoaded = true;
                    this.trackImpressions();
                });

            window.Echo.connector.pusher.connection.bind('connected', () => {
                this.$http.post(apr.search_perform_delayed_url);
            });
        },

        appendSearchResult(result) {
            apr.search_apr.results.push(result);

            switch (_.get(result, 'provider')) {
                case 'APR':
                    this.apr_results++;
                    break;
                case 'PSF':
                    this.psf_results++;
                    break;
            }

            this.filterProducts();
            this.mapFitBounds();
        },

        reloadResultsFromServer() {
            this.$http.get(apr.search_results_url + '?r=' + Math.random()).then((response) => {
                this.more_coming = _.get(response, 'body.more_coming')
                if (!this.more_coming) {
                    window.onbeforeunload = null;
                }
                _.each(_.get(response, 'body.search_apr', []), (result) => {
                    this.appendSearchResult(result)
                })
            })
        },
    }
});

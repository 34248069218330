<template>
    <div class="searchbox-inline">
        <div id="search_results_overlay" v-if="isEditMode"></div>
        <div class="searchbox">

            <!-- FIELDS -->
            <form action="" method="post" autocomplete="off" ref="searchboxForm">
                <display-apr v-if="!isEditMode"></display-apr>
                <option-apr v-if="isEditMode" style="max-width: 1000px;"></option-apr>
            </form>

        </div>
    </div>
</template>

<script>
    import SearchboxMixin from "./SearchboxMixin.js";
    import RequestMixin from "./Requests.js";
    import OptionAPR from "./options/OptionsAPR.vue";
    import DisplayAPR from "./display/DisplayAPR.vue";

    export default {
        mixins: [ SearchboxMixin, RequestMixin ],
        components: {
            'option-apr': OptionAPR,
            'display-apr': DisplayAPR,
        },

        data() {
            return {
                type: 'searchbox-inline',
                isEditMode: false,
                packageType: '',
            }
        },

        mounted() {
            this.initSearchboxForm()
            this.packageType = 'APR';
            this.setData(apr.search_apr);
            this.$store.dispatch('updateSessionTab', this.currentTab);
        },

        methods: {
            setData(data) {
                let search = {
                    id: data.search.data.token,
                    airport: {
                        type: data.search.data.airport.data.type,
                        code: data.search.data.airport.data.code,
                        name: data.search.data.airport.data.name,
                        city: data.search.data.airport.data.address.city,
                        state_code: data.search.data.airport.data.address.state_code,
                        latitude: data.search.data.airport.data.geo.latitude,
                        longitude: data.search.data.airport.data.geo.longitude,
                    },
                    date1: data.search.data.checkindate.date_format_2,
                    date2: data.search.data.checkoutdate.date_format_2,
                    dates: data.search_details ? data.search_details.dates : null,
                };

                let tab = 'parking';
                this.$store.dispatch('updateTab', tab)
                this.$store.dispatch('updateSession', search);
            },

            setTab(tab) {
                if (this.sessionTab != tab) {
                    this.isEditMode = true
                }
                this.$store.dispatch('updatePackage', 'APR');
            },

            getPageHeading(tagName) {
                return this.headings.apr[tagName]
            },
        }
    };
</script>

import Modal from './../components/BsModal.vue'
import PaymentContainer from '../components/Checkout/PaymentContainer'

Vue.component('page-checkout', {

	components: {
		Modal,
		PaymentContainer
	},

	mixins: [],

	computed: {

		displayCredits() {
			if (!this.customer) {
				return false
			}

			if (!this.customer.credits) {
				return false
			}

			if (this.credits >= this.customer.credits) {
				return false
			}

			return true
		},

		allowedCredits() {
			if (!this.customer || !this.customer.credits) {
				return 0
			}

			return this.customer.credits
		}

	},

	watch: {
		coupon() {
			this.modalError = false
			this.modalErrorMessage = ''
		},

		billing: {
			handler: function () {
				let debouncedFunction = _.debounce(this.addBillingToSession.bind(this), 1000)
				debouncedFunction()
			},
			deep: true
		}
	},

	data: function () {
		return {
			showSummary: true,
			blackoutError: false,

			searchbox: {},

			expand_taxes: false,
			working: false,
			loading: false,

			modalErrorMessage: '',
			modalError: false,

			showCreditsForm: false,
			use_credits: null,

			displayParkingPerson: false,

			showCouponForm: false,
			coupon: apr.coupon,
			coupon_code: null,
			header_title: 'Guest Details',
			customer_id: null,
			step: 1,
			customer: {},
			credits: apr.credits,
			billing: {
				email: '',
				firstname: '',
				lastname: '',
				zipcode: '',
				phone: ''
			},
			card: {
				gateway_data: null
			},
			deviceData: null,

			search: apr.search,
			pricing: apr.pricing,
			parkinglot: apr.parkinglot,
			product: apr.product,

			errors: [],
			errorNoTimes: null,
			errorMessage: null,

			months: apr.months,
			years: apr.years,

			clone_search: {},

			times: apr.times
		}

	},

	mounted() {

		let self = this

		this.$root.$on('session-received', function (data) {
			
			if (data.login.id) {
				self.step = 2
				self.header_title = 'Customer Details'
				self.customer_id = data.login.id
				self.customer = data.customer
				dataLayer.push({
					'event': 'GaEvent',
					'EventCategory': 'CheckoutStep',
					'EventAction': 'Step',
					'EventLabel': '2'
				})
			} else {
				dataLayer.push({
					'event': 'GaEvent',
					'EventCategory': 'CheckoutStep',
					'EventAction': 'Step',
					'EventLabel': '1'
				})
			}

			if (apr.billing) {
				self.billing = apr.billing
			}

		})

		this.$root.$on('gateway-changed', (data) => {
			this.card = data
		});

		$('#Add_Coupon, #Add_Credits').on('hidden.bs.modal', function (e) {
			if (self.modalError) {
				this.cleanMessages()
				self.coupon = null
				self.credits = null
			}
		});

		$('#Edit_Times').on('hidden.bs.modal', function (e) {
			self.dismissTimesModal()
		});

		$('[data-toggle="popover"]').popover({
			html: true,
		})

	},

	methods: {

		showTimesModal() {
			this.clone_search = (JSON.parse(JSON.stringify(this.search)));
			$('#Edit_Times, #Select_Parking').modal('show')
		},

		dismissTimesModal() {
			for (const prop in this.clone_search) {
				if (this.clone_search[prop] != this.search[prop]) {
					this.search[prop] = this.clone_search[prop];
				}
			}
		},
		
		checkInput(e) {
			const NO_NUMBERS_ALLOWED_CHARS_REGEXP = /^([^0-9]*)$/;
			if (!NO_NUMBERS_ALLOWED_CHARS_REGEXP.test(e.key)) {
				e.preventDefault();
			}
		},
		submitReservation() {
			$('button').prop('disabled', true);
			this.loading = true

			this.$root.$emit('checkout::reservation-submitted', this.card);

			this.triggerCheckoutEvent('Complete Reservation')

			setTimeout(() => {
				this.$http.post(apr.complete_reservation_link, {
					billing: this.billing,
					card: this.card,
				}).then((response) => {

					if (response.body.id) {
						window.location.href = '/receipt/' + response.body.id + '/' + response.body.token

					} else {
						this.loading = false
					}

				}, (response) => {
					if (response.body.errors) {
						this.errors = response.body.errors

						if (this.errors.error !== undefined) {
							dataLayer.push({
								'event': 'GaEvent',
								'EventCategory': 'Checkout',
								'EventAction': 'Payment Gateway Error'
							});
							$("#modal-errors").modal("show")
						} else {
							dataLayer.push({
								'event': 'GaEvent',
								'EventCategory': 'Checkout',
								'EventAction': 'Validation error'
							});
						}
					}

					if (response.body.error) {
						this.errorMessage = response.body.error[0]

						dataLayer.push({
							'event': 'GaEvent',
							'EventCategory': 'Checkout',
							'EventAction': 'Payment Gateway Error'
						});

						setTimeout(() => {
							let position = $("#ErrorAlert").offset().top - 55;
							$("html,body").animate({scrollTop: position}, "slow")
						}, 100)
					}

					$('button').prop('disabled', false);
					this.loading = false
				})
			}, 500)
		},

		updateTimes() {
			this.working = true
			this.errorTimes = false
			this.blackoutError = false
			this.$http.post(apr.update_reservation_link, {
				checkintime: this.search.checkintime.default,
				checkouttime: this.search.checkouttime.default,
				checkindate: this.search.checkindate.date_format_1,
				checkoutdate: this.search.checkoutdate.date_format_1,
			}).then((response) => {
				$('#Edit_Times').modal('hide')
				this.updatePage(response)
				this.showSummary = true
			}, (response) => {
				this.showSummary = false
				if (response.body.data.error == 'times') {
					this.errorTimes = true
					this.errorCheckInTime = response.body.data.checkin
					this.errorCheckOutTime = response.body.data.checkout
				} else {
					this.blackoutError = true
				}
				this.working = false
			})
		},

		nextStep() {
			if (this.isValidEmail()) {
				this.$http.post(apr.abandoned_cart_link, {email: this.billing.email})
				this.step++
				this.errors = []
				dataLayer.push({
					'event': 'GaEvent',
					'EventCategory': 'CheckoutStep',
					'EventAction': 'Step',
					'EventLabel': '2'
				})
			} else {
				this.errors = {'billing.email': 'Invalid email address.'}
			}
		},

		addBillingToSession() {
			this.$http.post(apr.add_billing_link, {billing: this.billing})
		},

		updatePage(response) {
			setTimeout(() => {
				this.search = response.body.search
				this.pricing = response.body.pricing
				this.coupon = response.body.coupon
				this.cleanMessages()
				this.working = false
			}, 1500)
		},

		applyCoupon() {

			if (this.coupon_code) {

				this.working = true
				this.$http.post(apr.add_coupon_link, {code: this.coupon_code}).then((response) => {
					this.coupon = this.coupon_code
					this.updatePage(response)
					dataLayer.push({
						'event': 'GaEvent',
						'EventCategory': 'Coupon',
						'EventAction': 'Success',
						'EventLabel': this.coupon_code
					})
				}, (response) => {
					this.modalErrors('Sorry that is an invalid coupon code')
					dataLayer.push({
						'event': 'GaEvent',
						'EventCategory': 'Coupon',
						'EventAction': 'Failed',
						'EventLabel': this.coupon_code
					})
				})

				return
			}

			this.modalErrors('Please enter a coupon code')
		},

		applyCredits() {

			if (this.use_credits) {
				this.working = true
				this.$http.post(apr.add_credits_link, {credits: this.use_credits}).then((response) => {
					this.updatePage(response)
					this.credits = this.use_credits
				}, (response) => {
					this.modalErrors('Sorry we are unable to add your credits.')
				})

				return
			}

			this.modalErrors('Please enter a credit amount you would like to use.')

		},

		cleanMessages() {
			this.working = false
			this.modalError = false
			this.modalErrorMessage = ''
		},

		modalErrors(message) {
			this.working = false
			this.modalError = true
			this.modalErrorMessage = message
		},

		isValidEmail() {
			return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(this.billing.email);
		},

		triggerCheckoutEvent(action, label = '') {
			dataLayer.push({
				'event': 'GaEvent',
				'EventCategory': 'Checkout',
				'EventAction': action,
				'EventLabel': label
			});
		},

		toggleCoupon() {
			this.showCouponForm = !this.showCouponForm
		},

		toggleCredits() {
			this.showCreditsForm = !this.showCreditsForm
		},

		searchPath() {
			window.location.href = `/search/${this.search.airport.data.code}?token=${this.search.token}`
		},

		// checkoutLink(response)
		// {
		//     return response.receipt_url
		//     // return `/receipt/${response.body.id}/${response.body.receipt_token}`
		// },

		clearErrors(name) {
			this.errors[name] = null
		},
	}
});

import { mapGetters } from 'vuex'
import Datepicker from "../datepicker"

export default {
    mixins: [ Datepicker ],
    props: {
        passedHOne: String,
        passedHTwo: String,
        passedHThree: String,
        passedOption: String,
        showSf: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            titles: apr.searchbox_titles,
            headings: apr.searchbox_heading,
            content: apr.content,

            isCruiseport: false,

            package: this.passedOption,
            h1: this.passedHOne,
            h2: this.passedHTwo,
            h3: this.passedHThree,

            token: null,
            form: null,

            selectedOption: null,

            inProgressSearch: false,
        }
    },

    computed: {
        ...mapGetters([
            'session', 'query', 'shuttlefinder', 'currentTab', 'sessionTab'
        ]),
    },

    watch: {
        'package': function(val){
            this.$store.dispatch('updatePackage', val)
        },

        'query.airport.type': function (airportType) {
            airportType = _.toLower(airportType);

            // update searchbox on airport type change
            if (_.indexOf(['airport', 'cruiseport'], airportType) !== -1) { // airport & cruiseport
                if (airportType === 'cruiseport') {
                    this.isCruiseport = true
                } else {
                    this.isCruiseport = false
                }
            }
        },
    },

    mounted() {
        this.form = $(this.$refs.searchboxForm);

        // init from page data (airport page)
        if (_.get(window, 'apr.airport.code')) {
            let airport = {
                'type': _.toLower(_.get(window, 'apr.airport.type')),
                'code': _.get(window, 'apr.airport.code'),
                'name': _.get(window, 'apr.airport.name'),
                'city': _.get(window, 'apr.airport.address.city'),
                'state_code': _.get(window, 'apr.airport.address.state_code'),
                'latitude': _.get(window, 'apr.airport.geo.latitude'),
                'longitude': _.get(window, 'apr.airport.geo.longitude')
            };

            this.isCruiseport = (airport.type === 'cruiseport')
            this.$store.dispatch('updateAirport', airport)
        }else if(_.get(window, 'apr.content.airport_content.code')){
	        let airport = {
		        'type': _.toLower(_.get(window, 'apr.content.airport_content.type')),
		        'code': _.get(window, 'apr.content.airport_content.code'),
		        'name': _.get(window, 'apr.content.airport_content.name'),
		        'city': _.get(window, 'apr.content.airport_content.city'),
		        'state_code': _.get(window, 'apr.content.airport_content.address.state_code'),
		        'latitude': _.get(window, 'apr.content.airport_content.geo.latitude'),
		        'longitude': _.get(window, 'apr.content.airport_content.geo.longitude')
	        };
	
	        this.isCruiseport = (airport.type === 'cruiseport')
	        this.$store.dispatch('updateAirport', airport)
        }
    },

    methods: {
        setDates(date1, date2) {
            let dates = {
                'date1': date1,
                'date2': date2,
            };
            this.date1 = date1
            this.date2 = date2
            this.$store.dispatch('updateDates', dates);
        },

        isTouchDevice() {
            return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
        },

        initSearchboxTooltips() {
            if (this.isTouchDevice() === false) {
                setTimeout(() => {
                    $('.date_input input[data-toggle="tooltip"]').tooltip()
                }, 100)
            } else {
                console.log('Touch Device')
            }
        },

        formatDate(date) {
            return moment(date).format('ddd, MMM D')
        },

        triggerSearchboxEvent(action, label = '') {
            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': apr.event_category,
                'EventAction': action,
                'EventLabel': label
            });
        },
    }
}

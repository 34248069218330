import Heading from '../components/heading'
import Accordion from '../components/Accordion'

Vue.component('page-landing', {

    components: {Accordion},

    mixins: [Heading],

    data() {
        return {
            heading: null
        }
    },

    mounted() {
        this.heading = this.getQueryVariable('utm_term')
    },

    methods: {
        reviewReadMore(event) {
            let $readMore = $(event.target);
            let $first = $readMore.parent();
            let $second = $first.next();

            $first.hide();
            $second.show();
        },

        reviewReadLess(event) {
            let $readLess = $(event.target);
            let $second = $readLess.parent();
            let $first = $second.prev();

            $first.show();
            $second.hide();
        }
    }
});

export default {
    methods: {
        getSearchParams() {
            return {
                airport: this.query.airport.code,
                date1: this.query.date1,
                date2: this.query.date2,
                checkindate: this.query.date1,
                checkoutdate: this.query.date2,
            };
        },

        initSearchboxForm() {
            let self = this
            self.inProgressSearch = false;

            this.form.parsley().on('form:validate', function() {
                if(self.query.package == null){
                    $('.sleep .dropdown_container').addClass('error')
                }else{
                    $('.sleep .dropdown_container').removeClass('error')
                }

            }).on("form:submit", function () {

                return false;

            }).on('form:success', function (formInstance) {

                if (self.inProgressSearch) {
                    return false;
                }

                self.inProgressSearch = true;
                self.errors = null;

                self.triggerSearchboxEvent(apr.event_category, `Searched ${self.query.package}`, '')

                self.$http.post('/search', self.getSearchParams()).then(response => {
                    if (response.data.redirect) {
                        window.location.href = response.data.redirect
                    }else if(response.data.data.redirect_url){
                        window.location.href = response.data.data.redirect_url
                    }
                    setTimeout(() => {
                        self.inProgressSearch = false;
                    }, 7500)
                }, response => {
                    let errors = response.body

                    for (var errorName in errors) {
                        let $field = self.form.find(`input[name="${errorName}"]`);

                        if ($field.length) {
                            $field.parsley().addError('custom-error', {message: errors[errorName]});
                        }
                    }

                    self.inProgressSearch = false;
                });

                return false;
            });
        },
    }
}

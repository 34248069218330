export default {
    methods: {
        filterButtonClick: function (event) { // detect what you are clicking on. toggle hide if clicking button
            let self = this
            let $el = $(event.target)

            $el.toggleClass('menu-opened');
            var thisMenu = $el.siblings('.filter-menu');

            if ($el.hasClass('menu-opened')) {
                self.showFilterMenu(thisMenu);
            } else {
                self.hideFilterMenu();
            }
        },

        detectHideFilter: function () { // hide filter menu if you are click outside of the menu
            let self = this

            $(document).mouseup(function (e) {
                var container = $(".filter-button").parent();

                if (!container.is(e.target) // if the target of the click isn't the container...
                    && container.has(e.target).length === 0) // ... nor a descendant of the container
                {
                    self.hideFilterMenu();
                }
            });
        },

        showFilterMenu: function (menu) { // animate and show filter menu
            let filterMenu = menu;
            let filterParent = filterMenu.parent('.filter-container');
            let self = this

            /* this if/else if statement is to check if a current filter menu is already opened. if it is, close it.*/
            if (filterParent.hasClass('sort-by')) {
                let otherButton = $('.parking-options').find('.filter-button');

                if (otherButton.hasClass('menu-opened')) {
                    let otherMenu = $('.parking-options').find('.filter-menu');
                    self.hideSpecificMenu(otherMenu, otherButton);
                }
            }

            else if (filterParent.hasClass('parking-options')) {
                let otherButton = $('.sort-by').find('.filter-button');

                if (otherButton.hasClass('menu-opened')) {
                    let otherMenu = $('.sort-by').find('.filter-menu');
                    self.hideSpecificMenu(otherMenu, otherButton);
                }
            }
            /* end of if/else if statement */

            filterMenu.show();
            filterMenu.animate(
                {
                    opacity: 1,
                    top: '39px'
                },
                {
                    duration: 300
                }
            );
        },

        hideSpecificMenu: function (menu, button) { // this hides just one of the filters instead of hiding both
            button.removeClass('menu-opened');
            menu.animate(
                {
                    opacity: 0,
                    top: '0px'
                },
                {
                    duration: 300,
                    complete: function () {
                        $(this).hide();
                    }
                }
            );
        },

        hideFilterMenu: function () { // hide all filter menu and reset position
            let filterButton = $('.filter-button');

            filterButton.each(function () {
                $(this).removeClass('menu-opened');
            });

            $('.filter-menu').animate( // animate the filter menu disappearing then hide
                {
                    opacity: 0,
                    top: '0px'
                },
                {
                    duration: 300,
                    complete: function () {
                        $(this).hide();
                    }
                }
            );
        },

        toggleParkingOptionCheckBox: function (event) { // this function will highlight the label and add toggle checkbox that is hidden
            let $checkbox = $(event.target)
            let $label = $checkbox.closest('label')
            let product = $checkbox.data('product');

            dataLayer.push({
                'event': 'GaEvent',
                'EventCategory': 'FilterLots',
                'EventAction': 'filtered',
                'EventLabel': product
            })

            // console.log(product)

            if ($checkbox.is(':checked')) {
                $label.addClass('active-label');

                this.filter_options.push(product);
                this.filter_options = this.filter_options.filter((v, i, a) => a.indexOf(v) === i);

            } else {
                let index = _.findIndex(this.filter_options, (option) => {
                    return (option == product)
                })
                this.filter_options.splice(index, 1);

                $label.removeClass('active-label');
            }

            this.filterProducts();
        },

        selectSortByOption: function (type = 'APR') { // this will select the correct option that is hdiden
            var sortByLabel = $('.sort-by-menu').find('label');
            let self = this

            sortByLabel.click(function () {
                sortByLabel.each(function () {
                    $(this).removeClass('active-label');
                });
                var value = $(this).attr('data-option');

                dataLayer.push({
                    'event': 'GaEvent',
                    'EventCategory': (type == 'PSF' ) ? 'PSF Sort Hotels' : 'SortLots',
                    'EventAction': 'sorting',
                    'EventLabel': value
                })

                $(this).addClass('active-label');

                self.sort_option = value
                self.filterProducts()

                self.hideFilterMenu();
            });
        },
    }
}

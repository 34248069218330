Vue.component('app-header', {
    data: function () {
        return {
            id: null,
            role: 'guest',
			showMobileNav: false
        }
    },

    mounted() {
        let self = this

        this.$root.$on('session-received', function (data) {
            let login = data.login

            self.id = login.id
            self.role = login.role
        })
    }
});

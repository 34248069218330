import Datepicker from "./../components/datepicker";
import Modal from "./../components/BsModal.vue";
import Heading from "./../components/heading";
// import * as VueGoogleMaps from 'vue2-google-maps';

// if (apr.app_page_name === 'parking_lot') {
// 	Vue.use(VueGoogleMaps, {load: window.googleMapsConfig})
// }

Vue.component('page-lot', {

	components: {
		Modal,
		// 'gmap': VueGoogleMaps.Map,
		// 'gmarker': VueGoogleMaps.Marker,
		// 'ginfoWindow': VueGoogleMaps.InfoWindow
	},

	mixins: [Datepicker, Heading],

	data: function () {
		return {
			checkin_value: '',
			checkout_value: '',

			showFooter: false,

			lot_list: {},
			soldOut: false,
			step: 1,

			showAllPhotos: false,
			parking_max: 0,
			alternatives: apr.content.alternatives,
			alternatives_count: apr.alternatives_count,

			// mapObj: {
			// 	center: {lat: 0, lng: 0},
			// 	mapTypeId: "roadmap",
			// 	zoom: 10,
			// 	options: {
			// 		scrollwheel: false,
			// 		disableDefaultUI: false,
			// 	}
			// },
			// markers: [],

			search: {
				checkindate: {},
				checkoutdate: {},
				checkintime: {
					default: '12:00:00'
				},
				checkouttime: {
					default: '12:00:00'
				}
			},
			content: apr.content,
			parkinglot: apr.content.parkinglot,
			options: apr.content.parkinglot.options,
			searchbox: null,
			working: false,
			products: {},
			product: null,

			// cloneed form checkout
			errorTimes: null,
			errorCheckInTime: null,
			errorCheckOutTime: null,
			errorNoTimes: null,
			searchPermitted: false,

			times: apr.times,

            isUpdateTimesInProgress: false,
			proceedToCheckout: false
        }
	},

	mounted() {
		let self = this

		this.$root.$on('session-received', function (data) {
			if (data.searchbox) {
				self.searchbox = data.searchbox
				self.checkin_value = data.searchbox.checkindate;
				self.checkout_value = data.searchbox.checkoutdate;
				self.initDateComponent(data)
			} else {
				self.searchPermitted = true
			}
		})

        // let map = this.$refs.lotMap

        // this.setMapCenter()
        // setTimeout(() => {
        //     this.getPins()
        //     this.mapFitBounds(map)
		//
		// 	$('body').scrollspy({target: '#navbar-sticky', offset:100})
        // }, 500)

		this.initSearchboxDatepickers($('.checkout'))
		this.initPagination()

		$('body').addClass('showFooter')

		// this.createWaypoints()
		this.scrollStick();
		this.trackLogoClick();

		$('#Select_Parking').on('hide.bs.modal', function () {
			dataLayer.push({'event': 'GaEvent', 'EventCategory': 'Parkinglot', 'EventAction': 'Modal - Closed modal', 'EventLabel': ''})
		})

		$('body').scrollspy({ target: '#navbar-sticky' })

		$("#Select_Parking").on('hidden.bs.modal', function (e) {
			self.step = 1
		})

		if(apr.show_datepicker) {
			this.focusDate();
		}
	},

	watch: {

		'search.checkintime.default': () => {
			$('#timepicker1_v2').blur()
		},

		'search.checkouttime.default': () => {
			$('#timepicker2_v2').blur()
		},

		checkout_value(val) {
			if (val && this.checkin_value && this.searchPermitted) {
				this.updateSearchDates()
			}
		},

	},

	computed: {
		checkInTimes() {
			if (moment(this.checkin_value, 'MMM D, YYYY') > moment()){
				return apr.times
			} else {
				return _.pickBy(apr.times, function(value, key) {
					if(moment(key, 'HH:mm:ss') > moment()) {
						return true
					}
				})
			}
		},

		checkOutTimes() {
			if (moment(this.checkout_value, 'MMM D, YYYY') > moment()){
				return apr.times
			} else {
				return _.pickBy(apr.times, function(value, key) {
					if(moment(key, 'HH:mm:ss') > moment()) {
						return true
					}
				})
			}
		}
	},

	methods: {
		trackLogoClick() {
			$(document).on('click', '.navbar .navbar-brand', () => {
				dataLayer.push({
					'event': 'GaEvent',
					'EventCategory': 'Logo click',
					'EventAction': 'Click desktop',
					'EventLabel': ''
				})
			})
		},

		createWaypoints() {
			var waypoint = new Waypoint({
				element: $('#dates-component'),
				handler: function(direction) {
					if(direction == "down"){
						$('#dates-component').addClass('sticky')
					}else{
						$('#dates-component').removeClass('sticky')
					}
				}
			})

			var waypoint = new Waypoint({
				element: $('#navbar-sticky'),
				handler: function(direction) {
					if(direction == "down"){
						$('#navbar-sticky').addClass('sticky')
					}else{
						$('#navbar-sticky').removeClass('sticky')
					}
				}
			})
		},

		scrollStick() {
			$(window).scroll(function() {
				var height = $(window).scrollTop();
				var datesComp = $('.dates-container').offset();
				var navbarComp = $('.navbar-container').offset();
				if(height > datesComp.top) {
					$('#dates-component').addClass('sticky')
				}else{
					$('#dates-component').removeClass('sticky')
				}
				if(height > navbarComp.top) {
					$('#navbar-sticky').addClass('sticky')
				}else{
					$('#navbar-sticky').removeClass('sticky')
				}
			});
		},

		scrollPassedDates() {
			console.log('In Dates')
		},

		initPagination() {

			let page = this.getQueryVariable('reviews')

			if (page) {
				this.paginationLink(window.location.href, false)
			} else {
				this.appendPaginationLinks()
			}
		},

		initDateComponent(data) {
			if(data.searchbox.token) {
				this.working = true
				this.$http.put(`/parkinglot/${apr.content.parkinglot.id}/search/${data.searchbox.token}`, {
					checkindate: this.searchbox.checkindate,
					checkoutdate: this.searchbox.checkoutdate,
				}).then((response) => {
					this.alternatives = response.body.alternatives
					this.parkinglot = response.body.parkinglot
					this.options = response.body.parkinglot.options
					this.search = response.body.search.data
					this.products = response.body.parkinglot.products.data
					this.working = false
					this.searchPermitted = true
					this.setSelectPlaceholders();
					this.registerPriceComparisonEvent()

					this.initBackButton(response)

				}, (response) => {
					this.soldOut = true
					this.working = false
					dataLayer.push({
						'event': 'GaEvent',
						'EventCategory': 'Parkinglot',
						'EventAction': 'Lot Unavailable',
						'EventLabel': ''
					})
				})
			}
		},

		initBackButton(response) {
			// http://pastebin.com/EZ2Ka1DG
			let airportCode = response.body.search.data.airport.data.code.toLowerCase()

			let productUrl = window.location.href;
			let airportUrl = `/search/${airportCode}?token=${response.body.search.data.token}`;
			window.history.replaceState({action: "airport"}, "", airportUrl);
			window.history.pushState({action: "product"}, "", productUrl);

			window.addEventListener("popstate", function (event) {
				if (event.state && event.state.action === "airport") {
					window.location = airportUrl;
				}
			}, false);
		},

		searchLink() {
			if (this.searchbox) {
				return `/search/${this.content.airport.code}/?token=${this.searchbox.token}`
			}
		},

		updateSearchDates() {
			this.working = true
			dataLayer.push({'event': 'GaEvent', 'EventCategory': 'Parkinglot', 'EventAction': 'Calendar dates edited', 'EventLabel': ''})
			if(!this.parkinglot.service && this.parkinglot.on_airport){
				return this.searchParking()
			}
			
			if (this.search.token) {
				this.$http.put(`/parkinglot/${apr.content.parkinglot.id}/search/${this.search.token}`, {
					checkindate: this.checkin_value,
					checkoutdate: this.checkout_value,
				}).then((response) => {
					this.alternatives = response.body.alternatives
					this.options = response.body.parkinglot.options
					this.parkinglot = response.body.parkinglot
					this.working = false
					this.search = response.body.search.data
					this.setSelectPlaceholders()
					this.registerPriceComparisonEvent()
					$('html,body').animate({ scrollTop: 0 }, 'slow');
				}, (response) => {
					this.working = false
					if(response.body.data) {
						window.location.href = this.redirectToSearch(response.body.data[0].data.token)
					}
				})
			} else {
				this.$http.post(`/parkinglot/${apr.content.parkinglot.id}/search`, {
					checkindate: this.checkin_value,
					checkoutdate: this.checkout_value,
				}).then((response) => {
					this.alternatives = response.body.alternatives
					this.options = response.body.parkinglot.options
					this.parkinglot = response.body.parkinglot
					this.working = false
					this.search = response.body.search.data
					this.setSelectPlaceholders()
					this.registerPriceComparisonEvent()
					$('html,body').animate({ scrollTop: 0 }, 'slow');
				}, (response) => {
					this.working = false
					if(response.body.data) {
						window.location.href = this.redirectToSearch(response.body.data[0].data.token)
					}
				})
			}


		},

		updateTimes() {
			this.errorTimes = false
			this.errorNoTimes = false

            if (this.isUpdateTimesInProgress) {
                return
            }

			if (this.search.checkintime.default == '' && this.search.checkouttime.default == '') {
				this.errorNoTimes = 'both'
				return
			} else if (this.search.checkintime.default == '') {
				this.errorNoTimes = 'check-in'
				return
			} else if (this.search.checkouttime.default == '') {
				this.errorNoTimes = 'check-out'
				return
			}

            this.isUpdateTimesInProgress = true
            this.$http.put(`/parkinglot/${apr.content.parkinglot.id}/search/${this.search.token}?filter_by_parkinglot_id=true`, {
				checkintime: this.search.checkintime.default,
				checkouttime: this.search.checkouttime.default,
				checkindate: this.search.checkindate.date_format_1,
				checkoutdate: this.search.checkoutdate.date_format_1,
			}).then((response) => {
                this.isUpdateTimesInProgress = false
                this.search = response.body.search.data
				this.step++
				this.errorNoTimes = null

				if (response.body.search.product) {
					this.product = response.body.search.product
				} else {
					this.products = response.data.parkinglot.products.data
					if (Array.isArray(response.body.parkinglot.products.data)) {
						this.product = response.body.parkinglot.products.data[0].id
					} else {
						this.product = response.body.parkinglot.products.data[1].id
					}
				}

				this.products = response.body.parkinglot.products.data
	            this.registerPriceComparisonEvent()
			}, (response) => {
                this.isUpdateTimesInProgress = false
				if (response.body.checkintime && response.body.checkouttime) {
					this.errorNoTimes = 'both'
					return
				} else if (response.body.checkintime) {
					this.errorNoTimes = 'check-in'
					return
				} else if (response.body.checkouttime) {
					this.errorNoTimes = 'check-out'
					return
				}

				this.errorNoTimes = null

	            if(response.body.data){
					if (typeof response.body.data[0].data.times !== 'undefined') {
						this.errorTimes = true
						this.errorCheckInTime = response.body.data[0].data.times.checkin
						this.errorCheckOutTime = response.body.data[0].data.times.checkout
						dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Modal - Times Unavailable', 'EventLabel': ''})
					} else {
						window.location.href = this.redirectToSearch(response.body.data[0].data.token)
					}
	            }

			})

			dataLayer.push({'event': 'GaEvent', 'EventCategory': 'Parkinglot', 'EventAction': 'Modal - Times Entered', 'EventLabel': ''})
		},

		setSelectPlaceholders() {
			// for vue - to select default values
			if (this.search.checkintime.default === null || this.search.checkintime.default === undefined) {
				this.search.checkintime.default = '12:00:00'
			}
			if (this.search.checkouttime.default === null || this.search.checkouttime.default === undefined) {
				this.search.checkouttime.default = '12:00:00'
			}

			if (moment(this.checkout_value, 'MMM D, YYYY') < moment()){
				this.search.checkouttime.default = moment().add(15, 'minutes').endOf('hour').add(1, 'second').format('HH:mm:ss')
				console.log(moment().add(15, 'minutes').endOf('hour').add(1, 'second').format('HH:mm:ss'))
			}
			if (moment(this.checkin_value, 'MMM D, YYYY') < moment()){
				this.search.checkintime.default = moment().add(1, 'hour').startOf('hour').format('HH:mm:ss')
			}
		},

		showTimesModal() {
			if (!this.checkin_value || !this.checkout_value) {
				return this.focusDate()
			}
			dataLayer.push({'event': 'GaEvent', 'EventCategory': 'Parkinglot', 'EventAction': 'Modal - Open modal', 'EventLabel': ''})
			$('#Edit_Times, #Select_Parking').modal('show')
		},

		goToCarouselSlide(index){
			$('.carousel').carousel(index)
			dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Photos', 'EventLabel': 'Next'})
		},

		selectProduct(productId) {
			this.product = productId
			dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Modal - Parking Type Selected', 'EventLabel': ''})
		},

		redirectToSearch(token) {
			return `/search/soldout?token=${token}&airport=${this.content.airport.code}&parkinglot=${this.parkinglot.name}`
		},

		searchPath() {
			dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Modal - Times Unavailable - Link Clicked ', 'EventLabel': ''})
			window.location.href = `/search/${this.content.airport.code}?token=${this.search.token}`
		},

		goToCheckout() {
			this.proceedToCheckout = true
			$('button').prop('disabled', true)

			this.$http.put(`/parkinglot/${apr.content.parkinglot.id}/search/${this.search.token}/product`, {
				product: this.product
			}).then((response) => {
				window.location.href = this.checkoutLocation(response)
				dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Modal - Proceeded To Checkout', 'EventLabel': ''})
			}, (response) => {
				$('button').prop('disabled', false)
				alert('Sorry we are unable to process you request.')
				this.proceedToCheckout = false
			})

		},

		searchParking() {
			let self = this
			this.working = true

			let searchURL = `${apr.app_url}/search`;
			let searchParams = {
				airport: apr.content.airport.code,
				package: 'APR',
				date1: this.checkin_value,
				date2: this.checkout_value,
				guests: 2,
				rooms: 1,
				hotel_id: 0,
				checkindate: this.checkin_value,
				checkoutdate: this.checkout_value,
			};

			this.$http.post(searchURL, searchParams).then((response) => {
				if (response.data.redirect) {
					window.location.href = response.data.redirect
				}else if(response.data.data.redirect_url){
					window.location.href = response.data.data.redirect_url
				}
				setTimeout(() => {
					self.inProgressSearch = false;
				}, 7500)
			})
		},

		appendPaginationLinks() {
			$('link[rel=next], link[rel=prev]').remove();

			if (this.content.reviews.next_page_url) {
				$('head').append(`<link rel="next" href="${this.content.reviews.next_page_url}" />`)
			}

			if (this.content.reviews.prev_page_url) {
				$('head').append(`<link rel="prev" href="${this.content.reviews.prev_page_url}" />`)
			}
		},

		paginationLink(link, push) {
			if (link) {
				this.$http.get(link).then((response) => {
					this.content = response.data
					this.appendPaginationLinks()

					if (push) {
						$('html, body').animate({scrollTop: $("#reviews").offset().top}, 500)
					}

				}, (response) => {
					alert('Sorry we could not get that page for you')
				})
			}
		},

		focusDate() {
			$("body").animate({ scrollTop: 0 }, 1000);
			setTimeout(() => {
				$("input[name='checkindate']").trigger('click')
				dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Calendar dates edited', 'EventLabel': ''})
			}, 100)
		},

		setDates(start, end){
			this.checkin_value = start
			this.checkout_value = end
		},

		formatDate(date){
			if(date) {
				return moment(date).format(window.APR_DATE_FORMAT2);
			}
			return ''
		},

		checkoutLocation(response) {
			let payment = this.parkinglot.prepaid ? 'prepaid' : 'deposit'

			return `${apr.app_url}/checkout/${payment}?token=${this.search.token}`;
		},

		// setMapCenter(){
		// 	this.mapObj = {
		// 		center: {
		// 			lat: Number(this.content.parkinglot.geo.latitude),
		// 			lng: Number(this.content.parkinglot.geo.longitude)
		// 		},
		// 		mapTypeId: "roadmap",
		// 		zoom: 10,
		// 		options: {
		// 			scrollwheel: false,
		// 			disableDefaultUI: false,
        //             styles: [
		// 				{featureType: "poi", elementType: "labels", stylers: [{"visibility": "off"}]}
		// 			],
		// 			controlSize: 32,
		// 		}
		// 	}
		// },

		// getPins() {
		// 	this.markers = []
		// 	this.getAirportPin()
		// 	this.getLotPin()
		// },

		// getAirportPin() {
		// 	this.markers = [{
		// 		position: {
		// 			lat: Number(this.content.airport.geo.latitude),
		// 			lng: Number(this.content.airport.geo.longitude)
		// 		},
		// 		icon: {
		// 			url: apr.storage_url + '/img/icons/airport-pin.png',
		// 			scaledSize: new google.maps.Size(45, 36),
		// 			anchor: new google.maps.Point(23, 36),
		// 			labelOrigin: new google.maps.Point(22, 14)
		// 		},
		// 		animation: google.maps.Animation.FADE,
		// 		draggable: false,
		// 		clickable: false
		// 	}]
		// },
		//
		// getLotPin() {
		// 	this.markers.push({
		// 		position: {
		// 			lat: Number(this.content.parkinglot.geo.latitude),
		// 			lng: Number(this.content.parkinglot.geo.longitude)
		// 		},
		// 		icon: {
		// 			url: apr.storage_url + '/img/icons/lot-product-pin.png',
		// 			scaledSize: new google.maps.Size(45, 36),
		// 			anchor: new google.maps.Point(23, 36),
		// 			labelOrigin: new google.maps.Point(22, 14)
		// 		},
		// 		animation: google.maps.Animation.FADE,
		// 		draggable: false,
		// 		clickable: false
		// 	})
		// },
		//
		// mapFitBounds(map) {
		// 	let bounds = new google.maps.LatLngBounds();
		//
		// 	this.markers.forEach((marker) => {
		// 		let point = new google.maps.LatLng(marker.position.lat, marker.position.lng);
		// 		bounds.extend(point);
		// 	})
		//
		// 	map.fitBounds(bounds)
		// },

		registerTabEvent(event) {
			dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Tabs', 'EventLabel': event})
		},

		editTimes() {
			this.step = 1
			dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Modal - Edit Times', 'EventLabel': ''})
		},

		alternativeSelected(name) {
			dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': 'Alternative parking lot selected', 'EventLabel': name})
		},

		registerEvent(name) {
			dataLayer.push({'event': 'GaEvent', 'EventCategory':'Parkinglot', 'EventAction': name, 'EventLabel': ''})
		},
		
		registerPriceComparisonEvent(){
			let includes = ["YUL", "CLE", "BOS", "TPA", "YYZ", "LAX", "SEA", "DTW", "ORD", "MSP"];
			if (includes.includes(this.content.airport.code) && this.search.days && this.parkinglot.savings.savings_total_str) {
				dataLayer.push({
					'event': 'GaEvent',
					'EventCategory': 'Price comparison',
					'EventAction': this.search.days + ' Days - ' + this.parkinglot.savings.savings_total_str,
					'EventLabel': window.location.href
				})
			}
		}
	}
});

import "../../../vendor/js/datepicker";

export default {
	
    methods: {
        initSearchboxDatepickers($form, args = {}) {
            let self = this
            let $sdate = $form.find("input[name='checkindate']");
            let $edate = $form.find("input[name='checkoutdate']");
            let $dates = $form.find(".dates");

            // tab navigation
            $($sdate).add($edate).on("keyup", function () {
                $dates.data("dateRangePicker").openAndSetActive($(this).data("field"));
            });

            setTimeout(function () {
                $form.find("button.dropdown-toggle").on("keyup click", function (e) {
                    if ($dates.data("dateRangePicker").isOpened()) {
                        $dates.data("dateRangePicker").close();
                    }
                });
            }, 100);


            let options = {
                format: "MMM D, YYYY",
                showTopbar: true,
                duration: 0,
                stickyMonths: true,
                minDays: 2,
                startDate: moment().format("MMM D, YYYY"),
                setValue: function (s, s1, s2) {

                    let date1 = (s1 != "Invalid date") ? s1 : ''
                    let date2 = (s2 != "Invalid date") ? s2 : ''

                    self.setDates(date1, date2)

                    $sdate.triggerHandler("change")
                    $edate.triggerHandler("change")
                }
            };
            $.extend(options, args);

            $dates.dateRangePicker(options).bind("datepicker-opened", function () {
            	if($('#searchbox').length && window.innerHeight < 910 || window.innerWidth < 1300) {
			            $('html, body').animate({
				            scrollTop: $('.date-picker-wrapper').offset().top - 250
			            }, 500);
	            }
                self.destroyTooltips($form);
                self.removeErrors($form);

            }).bind("datepicker-closed", function () {
                self.initTooltips($form);
            });
        },

        initDatepicker($field, args = {}) {
            let self = this

            let options = {
                autoClose: true,
                singleDate: true,
                showShortcuts: false,
                showTopbar: false,
                singleMonth: true,
                duration: 0,
                startDate: moment().format("MMM D, YYYY"),
                format: "MMM D, YYYY",

                setValue: function (s, date) {
                    self.setDate(date, $field.prop('name'))
                    $field.val(date)
                }
            };

            $.extend(options, args);
            $field.dateRangePicker(options)
        },

        removeErrors($form) {
            let $sdate = $form.find("input[name='checkindate']");
            let $edate = $form.find("input[name='checkoutdate']");

            $sdate.parsley().reset()
            $edate.parsley().reset()
        },

        initTooltips: function ($form) {
            let isiPad = navigator.userAgent.match(/iPad/i) != null;
            if (!isiPad) {
                let form = $form || false;
                let params = {
                    animation: false,
                    container: "body",
                    trigger: "hover"
                };

                if (form) {
                    $(form).find("[data-toggle='tooltip']").tooltip(params);

                } else {
                    $("[data-toggle='tooltip']").tooltip(params);
                }
            }
        },

        destroyTooltips: function ($form) {
            let form = $form || false;

            if (form) {
                $(form).find("[data-toggle='tooltip']").tooltip("dispose");

            } else {
                $("[data-toggle='tooltip']").tooltip("dispose");
            }
        },
    }
}
